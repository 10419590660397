import React from 'react'
import CenteredContent from '../centered-content'
import Jumbotron from '../jumbotron'
import { videoList } from './video-constants'
import Video from './video'
import { lightBlue, darkBlue } from '../../shared/colors'
import { Div, H1 } from 'glamorous'

import { smallerThan, largerThan } from '../../shared/media-queries'

const AllVideos = () => {
	return (
		<Div>
			<Jumbotron
				excludeTop
				tiltRightColor={lightBlue}
				tiltLeftColor={darkBlue}
				tiltOnTop="tiltLeft"
			>
				<CenteredContent>
					<Div
						color="#fff"
						display="flex"
						flexDirection="column"
						alignItems="center"
						css={{
							[smallerThan('x-small')]: {
								paddingTop: '50px',
								paddingBottom: '30px',
							},
							[largerThan('x-small')]: {
								paddingTop: '90px',
								paddingBottom: '70px',
							},
						}}
					>
						<H1
							margin={0}
							css={{
								[smallerThan('x-small')]: {
									fontSize: '36px',
								},
								[largerThan('x-small')]: {
									fontSize: '56px',
								},
							}}
						>
							See BuildingConnected in action
						</H1>
					</Div>
				</CenteredContent>
			</Jumbotron>

			<Div
				css={{
					[smallerThan('x-small')]: {
						height: '50px',
					},
					[largerThan('x-small')]: {
						height: '100px',
					},
				}}
			/>

			<CenteredContent>
				<Div display="flex" flexWrap="wrap" justifyContent="space-between">
					{videoList.map(video => {
						if (video.doNotShow) {
							return false
						}
						return (
							<Video video={video} small linkTitle key={video.id}>
								{video.blurb}
							</Video>
						)
					})}
				</Div>
			</CenteredContent>
		</Div>
	)
}

export default AllVideos
