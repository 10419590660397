// @flow
import React from 'react'
import qs from 'query-string'

import Layout from '../components/layout'
import AllVideos from '../components/video/videos-all'
import isBrowser from 'shared/is-browser'

const VideosPage = (props: { location: { search: string } }) => {
	const videoId = qs.parse(props.location.search).v

	if (videoId) {
		if (isBrowser) {
			window.location.href = `/video/?v=${String(videoId)}`
		}
		return null
	}

	return (
		<Layout
			title="Preconstruction Product Videos | BuildingConnected"
			description="Get a behind-the-scenes look at our latest preconstruction tools—plus, how BuildingConnected will help you save time and win more work."
		>
			<AllVideos />
		</Layout>
	)
}

export default VideosPage
